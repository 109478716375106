import React, { PureComponent } from 'react'
import {Header, Footer, Overlay} from '../common/index'
import '../../assets/styles/app.scss'
import { addEmbedScript, addHeadScript } from '../../utils/import-embed-script';
import { defaultFacebookPixel, safekidsFacebookPixel } from '../common/FacekookPixel';

export default class PageLayout extends PureComponent {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        addEmbedScript();
        addHeadScript(defaultFacebookPixel)
        if (this.props.activeSection === 'Safekids') {
            addHeadScript(safekidsFacebookPixel)
        }
    }

    render() {
        const className = this.props.className || 'generic-page';
        return (
            <div className={`app-root ${className} ${this.props.isReading ? 'is-reading' : ''}`}>
                <Header openClass={this.props.openClass} activeSection={this.props.activeSection} inferSearchSection={this.props.inferSearchSection} />
                {this.props.children}
                <Footer activeSection={this.props.activeSection} hideSocials={this.props.hideSocials} sitemap={this.props.sitemap} />
                <Overlay />
            </div>
        )
    }
}